import { createTheme } from "@material-ui/core/styles"

const theme = createTheme({
  navHeight: 74,
  sectionPadding: "padding-top: 1.5em; padding-bottom: 1.5em;",
  fonts: {
    primary: "Value Sans Bold, sans-serif",
    secondary: "Domaine, sans-serif",
    tertiary: "Value Sans Pro, sans-serif",
  },
  layout: {
    contentWidth: 1236,
  },
  palette: {
    primary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#1E1499",
      light: "#1A2933",
      dark: "#091118",
    },
    secondary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#8FF3AB",
      light: "#D8EAFD",
      dark: "#1F1D2F",
    },
    tertiary: {
      main: "#2C9ED0",
    },
    text: {
      primary: "#1E1499",
      secondary: "#0F1923",
      content: "#797979",
      tertiary: "#1A2933",
      disabled: "#10355A",
      hint: "#10355A",
      light: "#EEEEEE",
    },
    alternate: {
      main: "rgb(247, 249, 250)",
      dark: "#e8eaf6",
    },
  },
  typography: {
    fontFamily: `"Domaine", "Value Sans Bold","Value Sans Pro", "Open Sans", "Arial", sans-serif`,
  },
})
export default theme
